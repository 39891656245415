<template>
    <font-awesome-layers
        class="info-icon"
        v-b-tooltip.hover="{
            title: helpHint,
            delay: { show: showDelay, hide: 0 },
        }"
        @mouseenter.stop="cancelParentHover($event, true)"
        @mouseleave.stop="cancelParentHover($event, false)"
        @click.prevent.stop="() => {}">
        <font-awesome-icon :icon="['far', 'circle']" transform="grow-2" />
        <font-awesome-icon icon="info" transform="shrink-8" />
    </font-awesome-layers>
</template>

<script>
export default {
    name: 'InfoIcon',
    props: {
        helpHint: {
            type: String,
            default: '',
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },
};
</script>

<style scoped lang="scss">
.info-icon {
    margin-left: 0.1rem;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}
</style>
